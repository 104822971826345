<template>
  <div>
    <div class='name'>
      <h1>Edit Process</h1>
      <div class='name__buttons'>
        <div class='button-group'>
          <a href='#' @click.prevent.stop='toggleEdit' class='button button--secondary'>Cancel</a>
          <a href='#' @click.prevent.stop='submitForm' class='button button--primary'><i class='fas fa-save'></i>Update Process</a>
        </div>
      </div>
    </div>
    <form @submit.prevent.stop="submitForm">

      <div class='process-in-action__form' v-if='!openEdit'>
        <div class='form-group'>
          <label for='procedure_name' class='required'>Process Name</label>
          <input type='text' required id='procedure_name' v-model='name' placeholder='Our Standard Employee Termination Procedure'>
        </div>

        <div class='form-group'>
          <label for='procedure_description'>Process Description</label>
          <a href='#' v-if='!openProcessDescriptionEdit' @click.prevent.stop='toggleOpenProcessDescriptionEdit'>
            <template v-if='procedure.description'>Change Process Description</template>
            <template v-if='!procedure.description'>Write a brief description of the process</template>
          </a>
          <textarea v-if='openProcessDescriptionEdit' id='procedure_description' v-model='description'></textarea>
        </div>
      </div>
      <div class='form-group'>
        <label for='procedure_description'>Process Tasks</label>
        <a href='#' @click.prevent.stop='addTask' v-if='tasksLength == 0'>No tasks yet - start creating tasks to get things done!</a>
      </div>
      <div class='procedure-steps'>
        <draggable handle='.pstep__grab' v-model="procedure_tasks_attributes" @end="onSortEnd">
          <transition-group>
            <div :class='{"pstep pstep--admin": true, "pstep--active": index == openProcessEdit}' :key="'drag' + index" v-show='!task._destroy' v-for='(task, index) in procedure_tasks_attributes'>
              <a @click.prevent.stop='removeTask(index, task.id)' v-if='index == openProcessEdit' class='pstep__remove' href='#'><i class="far fa-trash"></i></a>
              <div class='pstep__header'>
                <a class='pstep__grab'>
                  <i class="far fa-ellipsis-v"></i><i class="far fa-ellipsis-v"></i>
                </a>
                <h1 class='pstep__title'><a href='#' @click.prevent.stop='toggleOpenProcessEdit(index)'>{{task.name ? task.name : "Untitled Task"}}</a></h1>
              </div>
              <div class='pstep__form' v-if='index == openProcessEdit'>
                <div class='form-group'>
                  <input type='text' placeholder='Task Name' :id='"task" + openProcessEdit.id' required :value='task.name' @input='e => updateTaskName(openProcessEdit, e)'>
                </div>
                <process-writer :dark_mode='dark_mode' :record_id='id' record_type='Procedure'></process-writer>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class='procedure__footer'>
        <div class='button-group'>
          <a href='#' @click.prevent.stop="addTask" class='button button--large button--success'><i class='fas fa-plus'></i>New Task</a>
        </div>
      </div>
    </form>

    
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import ProcessWriter from './Writer.vue';

export default {
  props: ["url", "can", "turn_off_completion", "id", "rate", "percentage", "dark_mode"],
  components: {
    draggable,
    ProcessWriter
  },
  computed: {
    ...mapState("procedures", {
      'procedure': 'procedure',
      'openProcessEdit': 'openProcessEdit',
      'openProcessDescriptionEdit': 'openProcessDescriptionEdit'
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength',
      'openEdit': 'openEdit'
    }),
    name: {
      get() {
        return this.$store.state.procedures.procedure.name
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureName', value)
      }
    },
    description: {
      get() {
        return this.$store.state.procedures.procedure.description
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureDescription', value)
      }
    },
    bulk: {
      get() {
        return this.$store.state.procedures.bulk
      },
      set(value) {
        this.$store.dispatch('procedures/updateBulk', value)
      }
    },
    procedure_tasks_attributes: {
      get() {
        return this.procedure.procedure_tasks_attributes
      },
      set(value) {
        this.$store.dispatch('procedures/update_tasks', value)
      }
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('procedures/submitForm');
    },
    updateTaskName(index, e) {
      this.$store.dispatch('procedures/updateTaskName', [index, e.target.value]);
    },
    onSortEnd () {
      this.$store.dispatch('procedures/resort');
    },
    toggleOpenProcessDescriptionEdit () {
      this.$store.dispatch('procedures/toggleOpenProcessDescriptionEdit');
    },
    toggleEdit () {
      this.$store.dispatch('procedures/toggleEdit');
    },
    toggleOpenProcessEdit (index) {
      this.$store.dispatch('procedures/toggleOpenProcessEdit', index);
    },
    addTask () {
      this.$store.dispatch('procedures/addTask');
      this.flash("New Task Added!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
    },
    removeTask (index, id) {
      this.$store.dispatch('procedures/removeTask', [index, id]);
    }
  }
}
</script>