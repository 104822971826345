
<template>
  <div class='dropdown-container'>
    <dropdown-menu
        v-model="show"
        :right="right"
        :hover="hover"
        :interactive="interactive"
>
  <slot name='trigger'></slot>
  <div slot='dropdown'><div class='dropdown__content' style='top: 38px'><slot name='content'></slot></div></div>
</dropdown-menu>
  </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu'
export default {
  props: [],
  components: {
    DropdownMenu
  },
  data () {
    return {
      show: false,
      right: false,
      hover: false,
      interactive: false
    }
  }
}
</script>

