<template>
  <div class='linker'>

    <input type='hidden' :name="name" :value="convertedSelected">

    <div class='field--link' v-for='(s,index) in selected'>
      <a :href='s.url' target="_blank">{{ s.name }}</a>
      <a href='#' class='field--link__remove' @click.prevent="remove(index)"><i class='fas fa-times'></i></a>
    </div>

    <div class='search search--hitcher' v-click-outside="onClickOutside">
      <icon></icon>
      <input type='text'
        @click="showSearch = true"
        @input="onInput"
        @keydown.esc="showSearch = false"
        v-model='q' placeholder="Search to add...">
      <div class='search__box' v-show='showSearch'>
        <header class='search__header'>
          <label v-if='company' style='margin: 0;'><input @change.prevent.stop="onInput" v-model='only_company' type='checkbox'>Search Only in Company</label>
        </header>
        <ul v-if='results.length > 0'>
          <li class='search__box__li__hitcher' v-for='(result,i) in results' :key="i">
            <h2>
              <a target="_blank" :href='result.url' class='button button--primary button--small'>View</a>
              <a href='#' @click.prevent="add(result.id, result.url, result.name)" class='button button--success button--small'>Choose</a>
              <a href='#' @click.prevent="add(result.id, result.url, result.name)">{{result.name}}
              <span class='search__span' v-if='result.object_type == "Password"'>&nbsp;{{result.company_name}}</span>
                <span class='search__span' v-if='!["Company", "Article", "Password"].includes(result.object_type)'>{{result.asset_type}} / {{result.company_name}}</span></a>
                
            </h2> 
          </li>
        </ul>
        <div class='empty' v-if='results.length == 0'>
          <p >No results found</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/icon.vue";
import vClickOutside from 'v-click-outside'

export default {
  props: ["name", "link_name", "linkable_id", "company", "existing"],
  mounted () {
    var that = this;
  },
  components: {
    Icon
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    remove (index) {
      this.selected.splice(index, 1)
    },
    add (id, url, name) {
      this.showSearch = false;
      this.selected.push({id: id, url: url, name: name})
    },
    onInput () {

      console.log(this.asset_type);

      var that = this;

      if (that.q.length > 0) {
        that.showSearch = true;
      } else {
        that.showSearch = false;
      }

      that.$api.get('/searches', {
        params: {
          q: that.q,
          type: "Asset",
          company: that.only_company ? that.company : "",
          asset_type: that.linkable_id
        }
      })
      .then(function (response) {
        console.log(response.data)
        that.results = response.data;
      })
    }
  },
  computed: {
    convertedSelected: function () {
      return JSON.stringify(this.selected)
    }
  },
  data: function () {
    return {
      q: "",
      results: [],
      only_company: true,
      showSearch: false,
      selected: this.existing
    }
  }
}
</script>

