<template>
  <div class="procedure">
    <div class='procedure-steps'>

      <div class='procedure__header' v-if='can && !turn_off_completion'>
        <p v-if='procedureCompletionPercentage'>{{ procedureCompletionPercentage }}</p>
        <p><b>{{procedureStatus}}</b></p>
        <div class='procedure__header-status' :style='{"width": procedureCompletionPercentage }'></div>
      </div>
      <div  :class='{"pstep": true, "pstep--active": index == openProcessView}' v-for='(task, index) in procedure.procedure_tasks_attributes' v-if='task.name.length > 0'>

        <header :class='{"pstep__header": true, "pstep__header--checked": task.completed, "pstep__header--disabled": turn_off_completion}'>
          <a href='#' :class='{"pstep__check": true}' v-if='!turn_off_completion' @click.prevent.stop="check(task.id, index, task.completed)">  <i class='far fa-check'></i>
          </a>
          <a class='pstep__check' @click.prevent.stop='' v-if='turn_off_completion'><i class='far fa-check'></i></a>
          <h1 :class='{"pstep__title": true, "pstep__title--full": !(can && !turn_off_completion)}'>
            <a href='#' @click.prevent.stop='toggleOpenProcessView(index)'>{{task.name}}</a>
            <div class='pstep__drop-indicator' v-if='can && !turn_off_completion'>
              <a href='#' @click.prevent.stop='OpenCompletionEditShortcut(index, task.completion_notes)'><i v-if='task.completed && task.completion_notes' class='fas fa-comment-alt-lines' title='Task Has Completion Notes Set'></i></a>
              <a href='#' @click.prevent.stop='OpenPriorityEditShortcut(index, task.priority)'><i v-if='task.priority && task.priority != "unsure"' :class="'fas fa-exclamation-circle pstep__flag--' + task.priority" title='Task Has Priority Set'></i></a>
              <a href='#' @click.prevent.stop='OpenAssignerEditShortcut(index, task.first_assigned_user_name, task.first_assigned_user_id)' class='pstep__person'>
              <span :title='task.first_assigned_user_name' v-if='task.first_assigned_user_initials'>{{task.first_assigned_user_initials}}</span>
              <span title='Unassigned' v-if='!task.first_assigned_user_initials'><i class='fal fa-user'></i></span>
              </a>
              <a href='#' class='pstep__due-date-indicator' @click.prevent.stop='OpenDaterEditShortcut(index, task.due_date)' title='Task Has Due Date Set'>{{task.formatted_due_date}}</a>

              <a href='#' @click.prevent.stop='toggleOpenProcessView(index)' v-if='index != openProcessView' class='pstep__expand'>
                <i class=" fal fa-chevron-down"></i>
              </a>
              <a href='#' @click.prevent.stop='toggleOpenProcessView(index)' v-if='index == openProcessView' class='pstep__expand'>
                <i class="fal fa-chevron-up"></i>
              </a>
            </div>
          </h1>
        </header>
        <main>
          <div v-if='openTask && openTask.name.length > 0 && openTask.id == task.id' class='pstep__content'>

            <div class='pstep__description ck-content'>
              <div class='pstep__completed' v-if='!turn_off_completion && openTask.completed'>
                Completed on {{openTask.completed_date}} by {{openTask.user_name}}
              </div>
              <div class='rich_text_content' v-if='openTask.description' v-html='openTask.description'></div>
              <div class='rich_text_content' v-if='!openTask.description'><p>No description yet...</p></div>
            </div>
            <div class='pstep__sections'>
              <div class='pstep__section' v-if='!turn_off_completion && can'>
                <div class='pstep__section-title'><h2>Assignee</h2></div>
                <div class='pstep__section-content'>

                  <form @submit.prevent.stop='submitAssignment(openTask.id, openProcessView)' v-if='openAssignerEdit === openProcessView'>
                    <multiselect :options="users"
                    label="name" :multiple='false' :show-no-results="false" :hide-selected="true" v-model='assignedUsers' track-by="code" placeholder="Type to search" open-direction="bottom" :searchable="true"></multiselect>

                    <div class='pstep__assign-btns'>
                      <input @click.prevent.stop='submitAssignment(openTask.id, openProcessView)' class='button button--small button--primary' type='submit' value='Assign User'>
                      <a href='#' class='pstep__unassign' @click.prevent.stop='clearAssignment(openTask.id, openProcessView)'>Unassign User</a>
                    </div>
                  </form>
                  <a href='' @click.prevent.stop='toggleOpenAssignerEdit(openProcessView, openTask.first_assigned_user_name, openTask.first_assigned_user_id)' class='pstep__person' v-if='openAssignerEdit != openProcessView'>
                    <template v-if='openTask.first_assigned_user_initials'>
                      <span>{{openTask.first_assigned_user_initials}}</span><h2>{{openTask.first_assigned_user_name}}</h2>
                    </template>
                    <template v-if='!openTask.first_assigned_user_initials'>
                      <span><i class='fal fa-user'></i></span><h2>Unassigned</h2>
                    </template>
                  </a>
                </div>
              </div>
              <div class='pstep__section' v-if='!turn_off_completion && can'>
                <div class='pstep__section-title'><h2>Due Date</h2></div>
                <form @submit.prevent.stop='submitDate(openTask.id, openProcessView)' v-if='openDaterEdit === openProcessView'>
                    <input type='date' v-model='newDate'>
                    <input @click.prevent.stop='submitDate(openTask.id, openProcessView)' class='button button--small button--primary' type='submit' value='Assign Due Date'>
                  </form>
                  <a href='' @click.prevent.stop='toggleOpenDaterEdit(openProcessView, openTask.due_date)' class='pstep__date' v-if='openDaterEdit != openProcessView'>
                    {{openTask.formatted_due_date || "Set Due Date"}}
                  </a>
              </div>
              <div class='pstep__section' v-if='!turn_off_completion && can'>
                <div class='pstep__section-title'><h2>Priority</h2></div>
                <div class='priority_select' v-if='openPriorityEdit === openProcessView'>
                  <a href='#' @click.prevent.stop='submitPriority(openTask.id, openProcessView, "low")' :class='{"selected": openTask.priority == "low"}'><i class='fas fa-exclamation-circle pstep__flag--Low'></i> Low</a>
                  <a href='#' @click.prevent.stop='submitPriority(openTask.id, openProcessView, "normal")' :class='{"selected": openTask.priority == "normal"}'><i class='fas fa-exclamation-circle pstep__flag--normal'></i> Normal</a>
                  <a href='#' @click.prevent.stop='submitPriority(openTask.id, openProcessView, "high")' :class='{"selected": openTask.priority == "high"}'><i class='fas fa-exclamation-circle pstep__flag--high'></i> High</a>
                  <a href='#' @click.prevent.stop='submitPriority(openTask.id, openProcessView, "urgent")' :class='{"selected": openTask.priority == "urgent"}'><i class='fas fa-exclamation-circle pstep__flag--urgent'></i> Urgent</a>
                  <a href='#' @click.prevent.stop='submitPriority(openTask.id, openProcessView, "unsure")' :class='{"selected": !openTask.priority || openTask.priority == "unsure"}'><i class='fal fa-exclamation-circle pstep__flag--low'></i> Clear</a>
                </div>
                <a href='' @click.prevent.stop='toggleOpenPriorityEdit(openProcessView, openTask.priority)' class='pstep__date' v-if='openPriorityEdit != openProcessView'>

                  <span class='pstep__priority-upcase' v-if='openTask.priority && openTask.priority != "unsure"'><i :class='"fas fa-exclamation-circle pstep__flag--" + openTask.priority'></i> {{openTask.priority }}</span>
                  <span v-if='!openTask.priority || openTask.priority == "unsure"'>Set a Priority</span>
                </a>
              </div>
              <div class='pstep__section' v-if='!turn_off_completion && openTask.completed && can'>
                <div class='pstep__section-title'><h2>Completion Notes</h2></div>
                <div class='pstep__section-content'>
                  <div class='pstep__noter' v-if='!turn_off_completion && openTask.completed'>
                    <div v-if='openCompletionEdit != openProcessView'>
                      <header>

                      </header>
                      <a href='#' v-if='openTask.completion_notes' @click.prevent.stop='toggleOpenCompletionEdit(openProcessView, openTask.completion_notes)' v-html='openTask.completion_notes'></a>
                      <a href='#' v-if='!openTask.completion_notes' @click.prevent.stop='toggleOpenCompletionEdit(openProcessView, openTask.completion_notes)'>Write a completion note...</a>
                    </div>
                    <form @submit.prevent.stop='submitCompletionNote(openTask.id, openProcessView)' v-if='openCompletionEdit === openProcessView'>
                      <textarea :id="'completionEdit' + openProcessView" v-model='newCompletionNote' placeholder='Your note here...'></textarea>
                      <input @click.prevent.stop='submitCompletionNote(openTask.id, openProcessView)' class='button button--small button--primary' type='submit' value='Update Completion Note'>
                    </form>
                  </div>
                </div>
              </div>
              <div class='pstep__actions'>
                <a href='#' @click.prevent.stop='copyCompletedTask' v-if='!turn_off_completion && openTask.completed'><i class='fas fa-copy'></i>Copy Task Completion</a>
                <a href='#' @click.prevent.stop='copyTaskLink'><i class='fas fa-copy'></i>Copy Link To Task</a>
              </div>
            </div><!--pstep__sections-->
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex'
import * as ClipboardFunctions from "../../lib/ClipboardFunctions.js";
import Multiselect from 'vue-multiselect'

export default {
  props: ["url", "users", "can", "turn_off_completion", "id", "rate", "percentage"],
  components: {
    Multiselect
  },
  data: function () {
    return {
     newCompletionNote: "",
     newDate: "",
     newPriority: "0",
     assignedUsers: "",
     priorityValuesList: ['None', 'Low', 'Normal', 'High', 'Urgent']
    }
  },
  computed: {
    ...mapState("procedures", {
      'procedure': 'procedure',
      'openProcessView': 'openProcessView',
      'showSplitLayout': 'showSplitLayout',
      'openCompletionEdit': 'openCompletionEdit',
      'openAssignerEdit': 'openAssignerEdit',
      'openDaterEdit': 'openDaterEdit',
      'openPriorityEdit': 'openPriorityEdit',
      'showFirework': 'showFirework'
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength',
      'openTask': 'openTask',
      'procedureStatus': 'procedureStatus',
      'procedureCompletionPercentage': 'procedureCompletionPercentage'
    })
  },
  mounted () {

  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      return capitalized.join(' ')
    }
  },
  methods: {
    submitCompletionNote(id, index) {
      if (this.newCompletionNote) {
        this.$store.dispatch('procedures/submitCompletionNote', [id, this.newCompletionNote.trim()]);
      } else {
        this.$store.dispatch('procedures/submitCompletionNote', [id, ""]);
      }
      this.newCompletionNote = ""
    },
    submitAssignment(id, index) {
      this.$store.dispatch('procedures/submitAssignment', [id, this.assignedUsers]);
      this.assignedUsers = ""
    },
    clearAssignment(id, index) {
      this.$store.dispatch('procedures/submitAssignment', [id, null]);
      this.assignedUsers = ""
    },
    submitDate(id, index) {
      this.$store.dispatch('procedures/submitDate', [id, this.newDate]);
      this.newDate = ""
    },
    submitPriority(id, index, value) {
      this.newPriority = value
      this.$store.dispatch('procedures/submitPriority', [id, this.newPriority]);
    },
    copyCompletedTask () {
      var that = this;
      var text = ""

      if (this.openTask.completion_notes) {
        text += `✔ COMPLETED - ${this.openTask.name} - ${this.openTask.completed_date}. Completion Notes: ${this.openTask.completion_notes}`;
      } else {
        text += `✔ COMPLETED - ${this.openTask.name} - ${this.openTask.completed_date}`;
      }

      ClipboardFunctions.copyText(text);
      that.flash("Copied!", 'success',
        {
          timeout: 3000,
          important: true
        }
      );
      text = "";
    },
    copyTaskLink () {
      var that = this;
      var text = ""

      ClipboardFunctions.copyText(that.openTask.url);
      that.flash("Copied!", 'success',
        {
          timeout: 3000,
          important: true
        }
      );
      text = "";
    },
    toggleOpenProcessView (index) {
      this.$store.dispatch('procedures/toggleOpenProcessView', index);
    },
    toggleOpenAssignerEdit (index, name, code) {
      this.assignedUsers = {"name": name, "code": code}
      this.$store.dispatch('procedures/toggleOpenAssignerEdit', index);
    },
    toggleOpenDaterEdit (index, newDate) {
      this.newDate = newDate
      this.$store.dispatch('procedures/toggleOpenDaterEdit', index);
    },
    toggleOpenPriorityEdit (index, newPriorityEdit) {
      this.newPriority = newPriorityEdit
      this.$store.dispatch('procedures/toggleOpenPriorityEdit', index);
    },
    toggleOpenCompletionEdit (index, newCompletionNote) {
      this.newCompletionNote = newCompletionNote
      this.$store.dispatch('procedures/toggleOpenCompletionEdit', index);
      //this.$nextTick(() => document.querySelector("#completionEdit" + index).focus())
    },
    OpenCompletionEditShortcut (index, newCompletionNote) {
      this.newCompletionNote = newCompletionNote
      this.$store.dispatch('procedures/toggleOpenProcessView', index);
      this.$store.dispatch('procedures/toggleOpenCompletionEdit', index);
    },
    OpenPriorityEditShortcut (index, newPriorityEdit) {
      this.newPriority = newPriorityEdit
      this.$store.dispatch('procedures/toggleOpenProcessView', index);
      this.$store.dispatch('procedures/toggleOpenPriorityEdit', index);
    },
    OpenDaterEditShortcut (index, newDate) {
      this.newDate = newDate
      this.$store.dispatch('procedures/toggleOpenProcessView', index);
      this.$store.dispatch('procedures/toggleOpenDaterEdit', index);
    },
    OpenAssignerEditShortcut (index, name, code) {
      this.assignedUsers = {"name": name, "code": code}
      this.$store.dispatch('procedures/toggleOpenProcessView', index);
      this.$store.dispatch('procedures/toggleOpenAssignerEdit', index);
    },
    check (id, index, completed) {
      this.$store.dispatch('procedures/check', [id, index, completed]);
    }
  }
}
</script>
