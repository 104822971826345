<template>
  <div>
    <div class='header'>
      <div class='header__content'>
        <a :href='dashboards_path' class='header__logo'>
          <Hudulogo v-if='!logo_url' class='hudu'></Hudulogo>
          <img :src='logo_url' v-if='logo_url' style='max-width: 100px;max-height: 40px;'>
        </a>
        <slot name='logo' @click.prevent.stop='switchSubMenu'></slot>
        <nav class='mobile-nav'>
          <a href='#' @click.prevent.stop='toggleMenu' class='header__a header__button'><bar-icon></bar-icon></a>
        </nav>
        <div class='header__nav'>
          <a :href='companies_path' :class='{"header__a header__a__tooltip": true, "active": active == 2}'>
            <h2 class='header__a-big'>{{structure_name}}</h2>
          </a>
          <a href='#'  v-if='!cant_access_global_kb' :class='{"header__a header__a__tooltip header__a--knowledge-base": true, "active": active == 3}' :href='articles_path'>
            <h2 class='header__a-big'>Global KB</h2>
          </a>
          <a href='#' v-if='!cant_access_personal_vault' :class='{"header__a header__a__tooltip header__a--vault": true, "active": active == 8}' :href='vault_path'>
            <h2 class='header__a-big'>My Vault</h2>
          </a>

          <a v-if='is_admin' :class='{"header__a header__a__tooltip header__a--admin": true, "active": active == 7}' :href='admin_path'>
            <h2 class='header__a-big'>Admin</h2>
          </a>
          <a v-if='is_editor && !is_admin' :class='{"header__a header__a__tooltip header__a--admin": true, "active": active == 7}' :href='admin_path'>
            <h2 class='header__a-big'>Tools</h2>
          </a>
        </div>
        <div :class='{"header__search": true, "header__search--expanded": showSearcher}'>
          <icon></icon>
          <input type='search'
            autocomplete="off" id='mainSearchInput' ref="searchInput" :placeholder="search_placeholder" @input='changeSearch'>
        </div>
        <div class='header__nav header__nav--highlight'>
          <a @click.prevent.stop="switchDropper(8)" :class="{'header__a__tooltip header__a--resync': true, 'header__a': true}" href='#'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256zM232 120V256v12.8l10.7 7.1 96 64 20 13.3 26.6-39.9-20-13.3L280 243.2V120 96H232v24z"/></svg>
          </a>
          <a @click.prevent.stop="switchDropper(9)" :class="{'header__a__tooltip header__a--resync': true, 'header__a': true}" href='#'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 288L39.8 263.8C14.3 238.3 0 203.8 0 167.8C0 92.8 60.8 32 135.8 32c36 0 70.5 14.3 96 39.8L256 96l24.2-24.2c25.5-25.5 60-39.8 96-39.8C451.2 32 512 92.8 512 167.8c0 36-14.3 70.5-39.8 96L448 288 256 480 64 288z"/></svg>
          </a>
          <a @click.prevent.stop="switchDropper(10)" :class="{'header__a__tooltip header__a--resync': true, 'header__a': true }" href='#'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M160 0V64H288V0h64V64h96v96H0V64H96V0h64zM0 192H448V512H0V192zM64 320h64V256H64v64zm128-64v64h64V256H192zm192 64V256H320v64h64zM64 448h64V384H64v64zm192-64H192v64h64V384zm64 64h64V384H320v64z"/></svg>
          </a>
          <a v-if='show_sync_bar' @click.prevent.stop="switchSyncBar" :class="{'header__a__tooltip header__a--resync': true, 'header__a': true }" href='#'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M128 0H64V32v96h64V32 0zM320 0H256V32v96h64V32 0zM32 160H0v64H32v32c0 77.4 55 142 128 156.8V512h64V412.8c12.3-2.5 24.1-6.4 35.1-11.5c-2.1-10.8-3.1-21.9-3.1-33.3c0-80.6 54.1-148.5 128-169.4V160H352 32zM576 368c0-79.5-64.5-144-144-144s-144 64.5-144 144s64.5 144 144 144s144-64.5 144-144zm-65.4-32l-11.3 11.3-72 72L416 430.6l-11.3-11.3-40-40L353.4 368 376 345.4l11.3 11.3L416 385.4l60.7-60.7L488 313.4 510.6 336z"/></svg>
          </a>
          <a @click.prevent.stop="switchOnboarder" :class='{"header__a header__a__tooltip header__a--learn": true, "active": active == 9}' href='#'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M640 176L320 288 127.8 220.7l198.1-77.8 14.9-5.9L329 107.3l-14.9 5.9-224 88-8.7 3.4L80 204V346.8c15.4 25.1 27.8 68.4 0 133.2L0 464s32.5-46.5 48-96.9V192.8L0 176V144L320 32 640 144v32zM143.6 260.2l165.9 58.1 10.6 3.7 10.6-3.7 165.9-58.1L512 408c0 35.3-86 72-192 72s-192-36.7-192-72l15.6-147.8z"/></svg>
          </a>
        </div>

        <a href='#' @click.prevent.stop="switchDropper(3)" class='profile'>
          <img :src='image' width='35' height='35'><h3 class='profile__name'>{{short_name}}</h3>
        </a>
      </div>
    </div>
    <div class='drop-header' v-if='showDrop'>
      <a
            :class='{"header__a header__a--home": true, "active": active == 1}' :href='dashboards_path'><i class='fal fa-home'></i></a>
      <a
      :class='{"header__a header__a--companies": true, "active": active == 2 }'
      :href='companies_path'><i class='fal fa-store-alt'></i></a>
      <a  v-if='!cant_access_global_kb' :class='{" header__a header__a--knowledge-base": true, "active": active == 3}' :href='articles_path'><i class='fal fa-book-reader'></i></a>
      <a :href='vault_path' v-if='!cant_access_personal_vault' :class="{'header__a': true, 'active': active == 8 }"><i class="fal fa-user-lock" style='margin: 0'></i></a>
      <a v-if='is_admin' :class='{"header__a header__a--admin": true, "active": active == 7}' :href='admin_path'><i class='fal fa-cog'></i></a>
      <a v-if='is_editor && !is_admin' :class='{"header__a header__a--admin": true, "active": active == 7}' :href='admin_path'><i class='fal fa-tools'></i></a>
      <a v-if='show_sync_bar' @click.prevent.stop="switchSyncBar" :class="{'header__a': true, 'active': showSyncBar }" href='#'><i class="fal fa-sync" style='margin: 0'></i></a>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Icon from "../components/icon.vue";
import DownArrow from "../components/DownArrow.vue";
import VTooltip from 'v-tooltip';
import Hudulogo from "../components/Hudulogo.vue";
import CompaniesIcon from "../components/icons/CompaniesIcon.vue";
import ArticlesIcon from "../components/icons/ArticlesIcon.vue";
import VaultIcon from "../components/icons/VaultIcon.vue";
import AdminIcon from "../components/icons/AdminIcon.vue";
import ResyncIcon from "../components/icons/ResyncIcon.vue";
import BarIcon from "../components/icons/BarIcon.vue";


export default {
  components: {
    Icon,
    BarIcon,
    CompaniesIcon,
    ArticlesIcon,
    VaultIcon,
    AdminIcon,
    ResyncIcon,
    DownArrow,
    Hudulogo
  },
  directives: {
    'tooltip': VTooltip
  },
  props: [
    "search_placeholder",
    "name",
    "image",
    "count",
    "tab",
    "logo_url",
    "show_menu",
    "user_role",
    "show_sync_bar",
    "account_name",
    "user_name",
    "structure_name",
    "dashboards_path",
    "companies_path",
    "process_templates_path",
    "articles_path",
    "admin_path",
    "vault_path",
    "short_name",
    "is_admin",
    "is_editor",
    "billing_successful",
    "cant_access_personal_vault",
    "cant_access_global_kb"
    ],
  computed: {
    ...mapGetters("globals", {
      'showVault': 'showVault',
      'showSyncBar': 'showSyncBar',
      'showDropper': 'showDropper',
      'showSearcher': 'showSearcher',
      'showSubMenu': 'showSubMenu',
      'showOnboarder': 'showOnboarder',
      'currentSubOpen': 'currentSubOpen'
    }),
    active () {
      if (this.showVault || this.showSyncBar || this.showSubMenu) {
        return 0
      } else if (this.tab == "dashboard") {
        return 1
      } else if (this.tab == "companies") {
        return 2
      } else if (this.tab == "articles") {
        return 3
      } else if (this.tab == "process_templates") {
        return 4
      } else if (this.tab == "admin") {
        return 7
      } else if (this.tab == "vault") {
        return 8
      } else if (this.tab == "onboarder") {
        return 9
      }
    }
  },
  methods: {
    toggleMenu () {

      this.showDrop = !this.showDrop;

      var companySidebar = document.querySelector(".company-sidebar");

      if (companySidebar.style.display === "none" || companySidebar.style.display === "" ) {
        companySidebar.style.display = "block";
      } else {
        companySidebar.style.display = "none";
      }

    },
    toggle(newSub) {
      this.$store.commit('globals/changeSub', newSub)
    },
    changeSearch(e) {
      this.$store.commit('globals/changeSearchQuery', e.target.value)
    },
    switchVault () {
      if (this.showVault) {
        this.toggle(0)
      } else {
        this.toggle(4)
        this.$nextTick(() => document.querySelector("#vaultSearchInput").focus())
      }
    },
    switchSubMenu () {
      if (this.showSubMenu) {
        this.toggle(0)
      } else {
        this.toggle(6)
      }
    },
    switchOnboarder () {
      if (this.showSubMenu) {
        this.toggle(0)
      } else {
        this.toggle(7)
      }
    },
    switchSearch () {
      if (this.showSearcher) {
        this.toggle(0)
      } else {
        this.toggle(2)
        this.$nextTick(() => document.querySelector("#mainSearchInput").focus())
      }
    },
    switchDropper (index) {
      if (index == this.currentSubOpen) {
        this.toggle(0)
      } else {
        this.toggle(index)
      }

    },
    switchSyncBar () {
      if (this.showSyncBar) {
        this.toggle(0)
      } else {
        this.toggle(5)
      }
    },
  },
  data () {
    return {
      isSubMenuShown: false,
      isShown: false,
      showDrop: false,
      structure_name_msg: "test"
    }
  }
}
</script>
