<template>
  <div>
    <a href='#' class='button button--secondary button--more' @click.prevent.stop='isShown = true' title='More Options'><i class='far fa-ellipsis-h'></i></a>
    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='isShown'>
        <div :class='{"manage_modal": true, "manage_modal--small": small}'>
          <header>
            <h1><i class='far fa-ellipsis-h icon'></i>More Options</h1>
            <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
          </header>
          <slot >
            
          </slot>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport';
import vClickOutside from 'v-click-outside'
export default {
  props: ["name", "small"],
  components: {
    Teleport
  },
  mounted () {
    
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside (event) {
      this.isShown = false
    },
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>