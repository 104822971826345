<template>
  <div class='procedure-editor'>
    <flash-message></flash-message>
    <template v-if='!showEdit'>
      <header class='name name--entity'>
        <article>
          <input type='text'
          <h1>{{procedure.name}} <span v-if='asset_name'>for {{asset_name}}</span></h1>
          <p v-if='procedure.description' v-html='procedure.description'></p>
        </article>
        <div class='name__buttons'>
          <div class='button-group'>
            <template v-if='can'>
              <template>
                <a href='#' @click.prevent.stop='toggleEdit' class='button button--secondary'><i class='fas fa-edit'></i>Edit</a>
                <a href='#' v-if='procedure.completed > 0 && !turn_off_completion' class='button button--secondary' @click.prevent.stop='copyCompletedTasks'><i class='fas fa-copy'></i>Copy Completed Tasks ({{procedure.completed}})</a>
              </template>
            </template>
            <slot></slot>
          </div>
        </div>
      </header>
      <slot name='flags'></slot>
      <div class='alert' v-if='turn_off_completion'>
        <i class='fal fa-info-circle icon'></i>This process is in template-mode. Tasks can't be completed here!
      </div>
      <article v-if='showDescription && !showEdit' class='procedure__description ck-content' v-html="procedure.description"></article>
      <process-view :users='users' v-if='!showEdit' :turn_off_completion='turn_off_completion' :can='can' />
    </template>
    <process-admin v-if='showEdit' :dark_mode='dark_mode' />
    <div class='procedure__blank' v-if='tasksLength == 0 && !showEdit'>
      <h3>There are no tasks in this process yet.</h3>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex'
import ProcessAdmin from './processor/Admin.vue';
import ProcessView from './processor/View.vue'
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";

export default {
  props: ["url", "users", "can", "turn_off_completion", "id", "dark_mode", "asset_name", "structure_name"],
  components: {
    ProcessAdmin,
    ProcessView
  },
  computed: {
    ...mapState("procedures", {
      'showEdit':  'showEdit',
      'procedure': 'procedure',
      'showDescription': 'showDescription',
      'openProcessView': 'openProcessView',
      'openProcessEdit': 'openProcessEdit',
      'openCompletionEdit': 'openCompletionEdit',
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength',
      'procedureJSON': 'procedureJSON'
    }),
    name: {
      get() {
        return this.$store.state.procedures.procedure.name
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureName', value)
      }
    }, 
    description: {
      get() {
        return this.$store.state.procedures.procedure.description
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureDescription', value)
      }
    },
    procedure_tasks_attributes: {
      get() {
        return this.procedure.procedure_tasks_attributes
      },
      set(value) {
        this.$store.dispatch('procedures/update_tasks', value)
      }
    }
  },
  mounted () {
    this.$store.dispatch('procedures/setState', [this.url, this.can, this.template])
    this.$store.dispatch('procedures/fetch');

  },
  methods: {
    toggleEdit () {
      this.$store.dispatch('procedures/toggleEdit');
    },
    toggleDescription () {
      this.$store.dispatch('procedures/toggleDescription');
    },
    toggleSplitLayout () {
      this.$store.dispatch('procedures/toggleSplitLayout');
    },
    copyCompletedTasks () {

      if (this.procedure.completed == 0) {
        return alert("Complete tasks first!")
      }
      var that = this;
      var text = "";
      that.procedure.procedure_tasks_attributes.forEach ( function (item, idx) {
        if (item.completed ) {
          if (item.completion_notes) {
            text += `✔ COMPLETED - ${item.name} - ${item.completed_date}. Completion Notes: ${item.completion_notes}`;
          } else {
            text += `✔ COMPLETED - ${item.name} - ${item.completed_date}`;
          }
           
          if (idx != that.procedure.procedure_tasks_attributes.length - 1) {
            text += "\n";
          }
        }
      })
      ClipboardFunctions.copyText(text);

      that.flash("Copied!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
      text = "";
    }
  }
}
</script>

