<template>
  <section class='company-summary__section'>
    <header class='company-summary__section-header'>
      <h2>Quick Notes</h2>
      <div class='button-group' v-if='can'>
        <a href='#' v-if='!isShown' class='button button--secondary button--small' @click.prevent.stop="isShown = !isShown"><i class='fas fa-edit'></i> Edit</a>
        <a href='#' v-if='isShown' class='button button--secondary button--small' @click.prevent.stop="cancel">Cancel Editing</a>
      </div>
    </header>
    <div v-if='!isShown' class='rich_text_content' v-html='notes'></div>
    <div class='quick_notes__writer' v-if='isShown'>
      <slot></slot>
    </div>
  </section>
</template>

<script>
import Writer from './writer.vue';

export default {
  props: ["company_url", "prenotes", "can"],
  components: {
    Writer
  },
  methods: {
    cancel () {
      document.location.reload()
    },
    save () {
      var that = this;

      that.$api.put(that.company_url, {
        company: {
          notes: that.notes
        }
      })
      .then(function (response) {
        document.location.reload()
      })
    }
  },
  data () {
    return {
      notes: this.prenotes || "",
      isShown: false
    }
  }
}
</script>

